<template>
  <v-container>
    <div class="container">
      <v-row>
        <v-col
            md="4"
            v-for="post in posts"
            :key="post.id"
        >
          <v-card
              class="mx-auto mb-2"
              width="320"
              :max-height="post.content.length>=100?400:350"
          >
            <v-img v-if="!post.featured" src="../../assets/svg/subtle-prism.svg"  class="text-light">
              <v-card-title>
                <v-icon
                    large
                    left
                >
                  mdi-subtitles-outline
                </v-icon>
                <span class="text-h6 font-weight-light">{{ post.title }}</span>
              </v-card-title>

              <v-card-text class="text-h6 font-weight-bold">
                {{ TrimPostContent(post.content) }}
              </v-card-text>

              <v-card-actions>
                <v-list-item class="grow text-light">
                  <v-list-item-avatar color="grey">
                    <v-img
                        class="elevation-6"
                        alt=""
                        :src="post.writer.photo"
                    ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ post.writer.firstname }}</v-list-item-title>
                  </v-list-item-content>

                  <v-row
                      align="center"
                      justify="end"
                  >
                    <v-btn icon @click="SetFocusPost(post)">
                      <v-icon class="mr-1 text-light">
                        mdi-comment-eye
                      </v-icon>
                    </v-btn>
                    <span class="subheading mr-2"></span>
                  </v-row>
                </v-list-item>
              </v-card-actions>
            </v-img>

            <v-img v-if="post.featured" :src="post.featured"  class="text-light">
              <v-card-title>
                <v-icon
                    large
                    left
                >
                  mdi-subtitles-outline
                </v-icon>
                <span class="text-h6 font-weight-light">{{ post.title }}</span>
              </v-card-title>

              <v-card-text class="text-h6 font-weight-bold">
                {{ TrimPostContent(post.content) }}
              </v-card-text>

              <v-card-actions>
                <v-list-item class="grow text-light">
                  <v-list-item-avatar color="grey">
                    <v-img
                        class="elevation-6"
                        alt=""
                        :src="post.writer.photo"
                    ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ post.writer.firstname }}</v-list-item-title>
                  </v-list-item-content>

                  <v-row
                      align="center"
                      justify="end"
                  >
                    <v-btn icon @click="SetFocusPost(post)">
                      <v-icon class="mr-1 text-light">
                        mdi-comment-eye
                      </v-icon>
                    </v-btn>
                    <span class="subheading mr-2"></span>
                  </v-row>
                </v-list-item>
              </v-card-actions>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- DIALOGS -->
    <v-row justify="center">
      <v-dialog
          v-model="postDialog"
          scrollable
          hide-overlay
          max-width="300px"
          transition="dialog-bottom-transition"
      >
        <v-card v-if="focusPost">
          <v-card-title class="p-0">
            <v-toolbar
                dark
                color="deep-orange"
                fixed
                dense
            >
              <v-toolbar-title>Post</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn
                    icon
                    dark
                    @click="showPostComments()"
                >
                  <v-icon>mdi-comment-text-multiple-outline</v-icon>
                </v-btn>
                <v-btn
                    icon
                    dark
                    @click="postDialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
          </v-card-title>

          <v-card-text>
            <h3>
              {{ focusPost.title }}
            </h3>
            <v-img v-if="focusPost.featured" :src="focusPost.featured" height="240"></v-img>
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <v-avatar height="100" rounded>
                    <v-img :src="focusPost.writer.photo"></v-img>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-title>
                  <b>{{ focusPost.writer.firstname }}</b>
                </v-list-item-title>
              </v-list-item>
              <v-list-item-subtitle class="text-secondary">
                {{ prepedDate(focusPost.pubDate.toString()) }}
              </v-list-item-subtitle>
            </v-list>
            <v-divider></v-divider>
            <v-container>
              {{ focusPost.content }}
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
          v-model="commentsDialog"
          scrollable
          max-width="315px"
      >
        <v-card>
          <v-card-title>
            Comments
            <v-spacer></v-spacer>
            <v-btn
                @click="()=>{
                  commentsDialog = false
                  postDialog= true
                }"
                icon
                class="bg-danger"
                small
            >
              <v-icon color="white">
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text
              style="height: 70vh;"
              class="p-3"
              v-if="comments"
          >


            <v-card elevation="1" rounded class="mb-4" v-for="comment in comments" :key="comment.id">
              <v-row class="p-2">
                <v-col cols="3">
                  <v-avatar size="40" >
                    <v-img :src="comment.writer.photo" ></v-img>
                  </v-avatar>
                </v-col>
                <v-col cols="9" class="p-0 m-0">
                  <v-card-title class="p-0 pb-1 m-0 text-h6">
                    <small> <b>{{comment.writer.firstname}} </b> </small>
                  </v-card-title>
                  <v-card-subtitle class="p-0">
                    <small> <small>{{ prepedDate(comment.pubDate) }}</small></small>
                  </v-card-subtitle>
                  <v-card-text class="p-0 my-0">
                    {{comment.content}}
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>

          </v-card-text>
          <v-card-actions>
            <v-textarea
                v-model="newCommentText"
                append-icon="mdi-send"
                prepend-icon="mdi-comment"
                class="mx-2"
                label="Comment"
                @click:append="submitComment"
                rows="1"
            ></v-textarea>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import {uuid} from "vue-uuid";

export default {
  name: "Posts",
  data: () => ({
    commentsDialog: false,
    focusPost: null,
    postDialog: false,
    newCommentText:null
  }),
  created() {
    this.loadPosts();
  },
  methods: {
    ...mapActions(["loadPosts", "getMorePosts", "loadComments",'submitNewComment']),
    getMorePosts() {
      window.onscroll = () => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          let pList = [...this.posts]
          this.getMorePosts(pList[pList.length - 1])
        }
      }
    },
    SetFocusPost(postDoc) {
      this.focusPost = postDoc
      this.postDialog = !this.postDialog
    },
    prepedDate(dateString) {
      let d = moment(dateString).format('LLL')
      return d
    },
    postImg(value){
      return value==""?'../../assets/svg/subtle-prism.svg':value
    },
    TrimPostContent(content){
      return content.length >=100? content.substring(0, 99) : content;
    },
    showPostComments() {
      this.postDialog = !this.postDialog
      this.commentsDialog = !this.commentsDialog
      this.loadComments(this.focusPost.id)
    },
    submitComment(){
      const comment = {
        post: this.focusPost.id,
        content: this.newCommentText,
        writer: this.userId,
        pubDate: new Date().toUTCString(),
        id:uuid.v4()
      }
      this.submitNewComment(comment)
    }
  },
  mounted() {
    this.getMorePosts()
  },
  computed: {
    ...mapGetters({
      posts: "getPosts",
      comments:"getPostComments",
      userId:"getUid"
    }),
  },
}
</script>

<style scoped>
:root {
  --main: #f06d34;
  --accent: #222832;
}
</style>